import { useCartState, useMarket } from '@app/_context';
import { useCartQuery } from '@server/hooks';
import { getCheckoutPayments } from '@server/requests/checkout/getCheckoutPayments';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { getCartCookie } from '@/utils/cart/getCartCookie';
import { getFreeShippingThreshold } from '@/utils/cart/getFreeShippingThreshold';
import getShippingCost from '@/utils/cart/getShippingCost';

import { useGiftCard } from './giftcard/GiftCard.hooks';

export function useCheckoutPayments() {
    const cartCookie = getCartCookie();
    const { locale } = useMarket().state;
    const { cartId } = useCartQuery();

    const { data, refetch } = useQuery({
        queryKey: ['checkoutPayments', cartId],
        queryFn: () =>
            getCheckoutPayments({
                locale,
                cartId: cartCookie ?? '',
            }),
        enabled: Boolean(cartCookie),
    });

    return {
        data,
        refetch,
    };
}

export function useCheckoutSummary() {
    const { cart } = useCartState();
    const { hasEstimatedShipping, shippingCost } = useShippingCost();
    const giftCard = useGiftCard();

    const giftCardAmount = useMemo(() => {
        if (!giftCard || !cart) {
            return 0;
        }

        if (giftCard?.amount > cart?.totalGrossAmount) {
            return cart?.totalGrossAmount;
        }

        return giftCard?.amount;
    }, [giftCard, cart]);

    const cartTotal = useMemo(() => {
        let totalGrossAmount = cart?.totalGrossAmount ?? 0;

        if (hasEstimatedShipping) {
            totalGrossAmount += shippingCost;
        }

        return totalGrossAmount - (giftCardAmount ?? 0);
    }, [
        cart?.totalGrossAmount,
        hasEstimatedShipping,
        shippingCost,
        giftCardAmount,
    ]);

    return {
        cartTotal,
        giftCardAmount,
    };
}

export function useShippingCost() {
    const { cart } = useCartState();
    const { market } = useMarket().state;

    const shippingThreshold = useMemo(
        () => getFreeShippingThreshold(market.code, cart?.freightClass ?? 0),
        [market.code, cart?.freightClass],
    );

    const hasEstimatedShipping = useMemo(() => {
        if (cart?.totalFreight) {
            return false;
        }

        if ((cart?.totalGrossAmount ?? 0) >= shippingThreshold) {
            return false;
        }

        return true;
    }, [cart, shippingThreshold]);

    const shippingCost = useMemo(() => {
        return getShippingCost({
            cart,
            marketCode: market.code,
        });
    }, [cart, market.code]);

    return {
        hasEstimatedShipping,
        shippingCost,
    };
}
