import type { Cart } from '@server/gql/graphql';

import {
    DK_MAX_SHIPPING_COST,
    DK_MIN_SHIPPING_COST,
    EU_MAX_SHIPPING_COST,
    EU_MIN_SHIPPING_COST,
    FI_MAX_SHIPPING_COST,
    FI_MIN_SHIPPING_COST,
    NO_MAX_SHIPPING_COST,
    NO_MIN_SHIPPING_COST,
    SE_MAX_SHIPPING_COST,
    SE_MIN_SHIPPING_COST,
} from '@/utils/const';

import { getFreeShippingThreshold } from './getFreeShippingThreshold';

/**
 * Freight classes:
 *
 * 1 - Skrymmande Gods
 * 2 - Styckegods/Större paket
 * 3 - Små Paket
 * 4 - Varubrev / Post
 *
 */

export default function getShippingCost(props: {
    cart?: Cart;
    marketCode: string;
}) {
    const { cart, marketCode } = props;

    if (cart?.totalFreightInclVat) {
        return cart.totalFreightInclVat;
    }

    if (isAboveFreeShippingThreshold(marketCode, cart)) {
        return 0;
    }

    const isSmallPackage = (cart?.freightClass ?? 0) >= 4; // freightClass 4/5 are small packages

    switch (marketCode) {
        case 'dk':
            return isSmallPackage ? DK_MIN_SHIPPING_COST : DK_MAX_SHIPPING_COST;
        case 'fi':
            return isSmallPackage ? FI_MIN_SHIPPING_COST : FI_MAX_SHIPPING_COST;
        case 'no':
            return isSmallPackage ? NO_MIN_SHIPPING_COST : NO_MAX_SHIPPING_COST;
        case 'se':
            return isSmallPackage ? SE_MIN_SHIPPING_COST : SE_MAX_SHIPPING_COST;
        default:
            return isSmallPackage ? EU_MIN_SHIPPING_COST : EU_MAX_SHIPPING_COST;
    }
}

const isAboveFreeShippingThreshold = (marketCode: string, cart?: Cart) => {
    const threshold = getFreeShippingThreshold(
        marketCode,
        cart?.freightClass ?? 0,
    );
    return (cart?.totalGrossAmount ?? 0) >= threshold;
};
