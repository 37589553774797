import { getCookie } from 'cookies-next';

import { CART_COOKIE } from '@/config/cart';

export function getCartCookie() {
    const cartCookie = getCookie(CART_COOKIE);

    if (typeof cartCookie !== 'string') {
        return undefined;
    }

    return cartCookie;
}
