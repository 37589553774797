import {
    DK_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE,
    DK_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE,
    EU_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE,
    EU_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE,
    FI_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE,
    FI_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE,
    NO_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE,
    NO_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE,
    SE_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE,
    SE_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE,
} from '../const';

export const getFreeShippingThreshold = (
    marketCode: string,
    freightClass: number,
) => {
    const isSmallPackage = freightClass >= 4; // freightClass 4/5 are small packages
    switch (marketCode) {
        case 'dk':
            return isSmallPackage
                ? DK_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE
                : DK_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE;
        case 'fi':
            return isSmallPackage
                ? FI_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE
                : FI_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE;
        case 'no':
            return isSmallPackage
                ? NO_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE
                : NO_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE;
        case 'se':
            return isSmallPackage
                ? SE_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE
                : SE_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE;
        default:
            return isSmallPackage
                ? EU_FREE_SHIPPING_THRESHOLD_SMALL_PACKAGE
                : EU_FREE_SHIPPING_THRESHOLD_LARGE_PACKAGE;
    }
};
