import { getGiftCard } from '@server/requests/giftcard/getGiftCard';
import { useQuery } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';

import { GIFTCARD_PAYMENT_METHOD_ID } from '@/config/checkout';
import { getCartCookie } from '@/utils/cart/getCartCookie';

import { useCheckoutPayments } from '../Checkout.hooks';
import { GiftCardContext } from './GiftCard.context';

export function useGiftCard() {
    const { data: checkoutPayments } = useCheckoutPayments();

    const giftCard = useMemo(() => {
        return checkoutPayments?.payments?.find(
            (payment) => payment.paymentMethodId === GIFTCARD_PAYMENT_METHOD_ID,
        );
    }, [checkoutPayments?.payments]);

    return giftCard;
}

export function useGiftCardContext() {
    const context = useContext(GiftCardContext);

    if (!context) {
        throw new Error(
            'GiftCardContext must be used within a GiftCardProvider',
        );
    }

    return context;
}

export function useFetchGiftCard({
    locale,
    cardNo,
    cvc,
}: {
    locale: string;
    cardNo: string;
    cvc: string;
}) {
    const cartCookie = getCartCookie();

    return useQuery({
        queryKey: ['giftCard', cardNo],
        queryFn: () => {
            return getGiftCard({
                locale,
                cartId: cartCookie ?? '',
                paymentMethodId: GIFTCARD_PAYMENT_METHOD_ID,
                giftCard: { cardNo, cvc },
            });
        },
        enabled: false,
        retry: false,
    });
}
